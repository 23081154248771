// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wide-view {
  border-bottom: 1px solid #d5d5d5;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wide-view__close-chat-button {
  padding-left: 16px;
  padding-right: 8px;
  height: 100%;
  display: flex;
  gap: 8px;
  align-items: center;
}
.wide-view__close-chat-button__icon {
  font-size: 20px;
}
.wide-view__right-content {
  grid-column-start: 3;
  display: flex;
  align-items: center;
  height: 100%;
}
.wide-view__right-content__searchbar {
  margin-right: 8px;
  border: 2px solid black;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 4px;
}
.wide-view__right-content__searchbar__icon {
  padding-left: 8px;
}
.wide-view__right-content__searchbar__input {
  height: 30px;
}
.wide-view__right-content__icon-wrapper {
  display: flex;
  height: 100%;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 20px;
}
.wide-view__right-content__icon-wrapper.message-highlighted {
  color: white;
  background-color: var(--ion-color-primary);
}`, "",{"version":3,"sources":["webpack://./src/app/chat/components/chat-detail-actions-bar/chat-detail-actions-bar.component.scss"],"names":[],"mappings":"AAEA;EACE,gCAAA;EAEA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AAFF;AAIE;EACE,kBAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,QAAA;EACA,mBAAA;AAFJ;AAII;EACE,eAAA;AAFN;AAME;EACE,oBAAA;EAEA,aAAA;EACA,mBAAA;EACA,YAAA;AALJ;AAOI;EACE,iBAAA;EACA,uBAAA;EACA,mBAAA;EAEA,aAAA;EACA,mBAAA;EACA,QAAA;AANN;AAQM;EACE,iBAAA;AANR;AASM;EACE,YAAA;AAPR;AAWI;EACE,aAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;EACA,mBAAA;EAEA,eAAA;AAVN;AAYM;EACE,YAAA;EACA,0CAAA;AAVR","sourcesContent":["$light-grey: #d5d5d5;\r\n\r\n.wide-view {\r\n  border-bottom: 1px solid $light-grey;\r\n\r\n  height: 60px;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: space-between;\r\n\r\n  &__close-chat-button {\r\n    padding-left: 16px;\r\n    padding-right: 8px;\r\n    height: 100%;\r\n    display: flex;\r\n    gap: 8px;\r\n    align-items: center;\r\n\r\n    &__icon {\r\n      font-size: 20px;\r\n    }\r\n  }\r\n\r\n  &__right-content {\r\n    grid-column-start: 3;\r\n\r\n    display: flex;\r\n    align-items: center;\r\n    height: 100%;\r\n\r\n    &__searchbar {\r\n      margin-right: 8px;\r\n      border: 2px solid black;\r\n      border-radius: 10px;\r\n\r\n      display: flex;\r\n      align-items: center;\r\n      gap: 4px;\r\n\r\n      &__icon {\r\n        padding-left: 8px;\r\n      }\r\n\r\n      &__input {\r\n        height: 30px;\r\n      }\r\n    }\r\n\r\n    &__icon-wrapper {\r\n      display: flex;\r\n      height: 100%;\r\n      align-items: center;\r\n      padding-left: 12px;\r\n      padding-right: 12px;\r\n\r\n      font-size: 20px;\r\n\r\n      &.message-highlighted {\r\n        color: white;\r\n        background-color: var(--ion-color-primary);\r\n      }\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
