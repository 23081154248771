import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { KeycloakIframeComponent } from './auth/keycloak-iframe/keycloak-iframe.component';
import { LoggedInOutletComponent } from './common/components/logged-in-outlet/logged-in-outlet.component';
import { AuthGuard } from './guards/auth.guard';
import { FinishedRegistrationGuard } from './guards/finished-registration.guard';

const routes: Routes = [
    {
        path: '', canActivate: [AuthGuard], children: [
            {
                path: '',
                canActivate: [FinishedRegistrationGuard],
                component: LoggedInOutletComponent,
                children: [
                    // {
                    //     canActivate: [LoggedInBootstrapGuard],
                    //     path: '',
                    //     pathMatch: 'full'
                    // },
                    {
                        path: 'organization-management',
                        loadChildren: () => import('./organization-management/organization-management.module').then(m => m.OrganizationManagementModule)
                    },
                    {
                        path: 'dashboard',
                        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
                    },
                    {
                        path: 'chats',
                        loadChildren: () => import('./chat/chat.module').then(m => m.ChatPageModule)
                    },
                    {
                        path: 'orders',
                        loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule)
                    },
                ]
            },
            {
                path: 'complete-registration',
                loadChildren: () => import('./complete-registration/complete-registration.module').then(m => m.CompleteRegistrationModule)
            }
        ],
    },
    {
        path: 'welcome',
        loadChildren: () => import('./welcome/welcome.module').then(m => m.WelcomePageModule)
    },
    {
        path: 'login',
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
    },
    {
        path: 'auth',
        component: KeycloakIframeComponent
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
