import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export abstract class BaseApiService {

    constructor(protected http: HttpClient) { 
    }

    public get endpoint(): string {
        const urlParts = [
            environment.api.baseUrl,
            "api",
            "v" + environment.api.version,
            this.getEndpointPrefix()
        ]
        
        return urlParts.join("/")
    }

    protected abstract getEndpointPrefix(): string;

    public endpointUrl(endpointPath: string): string {
        const urlParts = [
            this.endpoint,
            endpointPath
        ];

        let url = urlParts.filter(p => !!p).join("/");

        return url;
    }
}