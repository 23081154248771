// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.general-top-bar {
  height: 60px;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.general-top-bar__right-section {
  font-size: 24px;
  display: flex;
  gap: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/chat/components/general-top-bar/general-top-bar.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AACF;AACE;EACE,eAAA;EACA,aAAA;EACA,SAAA;AACJ","sourcesContent":[".general-top-bar {\r\n  height: 60px;\r\n  padding: 12px;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: space-between;\r\n\r\n  &__right-section {\r\n    font-size: 24px;\r\n    display: flex;\r\n    gap: 20px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
