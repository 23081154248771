import { UserRoles } from "../common/enums/user-roles.enum";

export class User {

    constructor(
        public id: number,
        public username: string,
        public name: string,
        public memberships: number,
        public role: UserRoles) {
    }
}