// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.folder-row {
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
}
.folder-row__icon {
  font-size: 22px;
}
.folder-row__user-image {
  height: 24px;
  border-radius: 50%;
}
.folder-row.unclickable {
  color: grey;
}`, "",{"version":3,"sources":["webpack://./src/app/src/app/chat/components/folder/folder.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,QAAA;AACF;AACE;EACE,eAAA;AACJ;AAEE;EACE,YAAA;EACA,kBAAA;AAAJ;AAGE;EACE,WAAA;AADJ","sourcesContent":[".folder-row {\r\n  padding: 8px;\r\n  display: flex;\r\n  align-items: center;\r\n  gap: 8px;\r\n\r\n  &__icon {\r\n    font-size: 22px;\r\n  }\r\n\r\n  &__user-image {\r\n    height: 24px;\r\n    border-radius: 50%;\r\n  }\r\n\r\n  &.unclickable {\r\n    color: grey;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
