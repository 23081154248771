// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  height: 64px;
  display: flex;
  align-items: center;
  padding: 4px 16px;
  gap: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  z-index: 100;
}
:host button {
  padding: 0;
  background: none;
}
:host ion-menu-button {
  display: none;
}
:host .logo {
  display: block;
  width: 132px;
  height: 35px;
  background: url('logo.svg') no-repeat center;
  background-size: contain;
}
:host .right {
  margin-left: auto;
  display: flex;
  gap: 8px;
}
:host .user-popover button {
  background: none;
}
@media screen and (min-width: 1240px) {
  :host ion-menu-button {
    display: block;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/common/components/top-bar/top-bar.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,QAAA;EACA,sBAAA;EACA,0CAAA;EACA,YAAA;AACJ;AACI;EACI,UAAA;EACA,gBAAA;AACR;AAEI;EACI,aAAA;AAAR;AAGI;EACI,cAAA;EACA,YAAA;EACA,YAAA;EACA,4CAAA;EACA,wBAAA;AADR;AAII;EACI,iBAAA;EACA,aAAA;EACA,QAAA;AAFR;AAMQ;EACI,gBAAA;AAJZ;AAQI;EACI;IACI,cAAA;EANV;AACF","sourcesContent":[":host {\r\n    height: 64px;\r\n    display: flex;\r\n    align-items: center;\r\n    padding: 4px 16px;\r\n    gap: 8px;\r\n    background-color: #fff;\r\n    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);\r\n    z-index: 100;\r\n\r\n    button {\r\n        padding: 0;\r\n        background: none;\r\n    }\r\n\r\n    ion-menu-button {\r\n        display: none;\r\n    }\r\n\r\n    .logo {\r\n        display: block;\r\n        width: 132px;\r\n        height: 35px;\r\n        background: url(\"../../../../assets/logo.svg\") no-repeat center;\r\n        background-size: contain;\r\n    }\r\n\r\n    .right {\r\n        margin-left: auto;\r\n        display: flex;\r\n        gap: 8px;\r\n    }\r\n\r\n    .user-popover {\r\n        button {\r\n            background: none;\r\n        }\r\n    }\r\n\r\n    @media screen and (min-width: 1240px) {\r\n        ion-menu-button {\r\n            display: block;\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
