// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-row {
  border-bottom: 1px solid #d5d5d5;
  display: flex;
  gap: 12px;
  padding: 10px;
  align-items: center;
}
.search-row__searchbar {
  width: 100%;
  background: #e7e7e7;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 4px;
}
.search-row__searchbar__icon {
  padding-left: 8px;
}
.search-row__searchbar__input {
  height: 30px;
}
.search-row__create-button {
  font-size: 18px;
  margin-left: auto;
  display: flex;
  align-items: center;
  background-color: var(--ion-color-primary);
  color: white;
  padding: 6px;
  border-radius: 50%;
}
.search-row__create-button:hover {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/chat/components/chats-people-searchbar/chats-people-searchbar.component.scss"],"names":[],"mappings":"AAGA;EACE,gCAAA;EAEA,aAAA;EACA,SAAA;EACA,aAAA;EACA,mBAAA;AAHF;AAKE;EACE,WAAA;EACA,mBAbc;EAcd,mBAAA;EAEA,aAAA;EACA,mBAAA;EACA,QAAA;AAJJ;AAMI;EACE,iBAAA;AAJN;AAOI;EACE,YAAA;AALN;AASE;EACE,eAAA;EACA,iBAAA;EAEA,aAAA;EACA,mBAAA;EAEA,0CAAA;EACA,YAAA;EAEA,YAAA;EACA,kBAAA;AAVJ;AAYI;EACE,eAAA;AAVN","sourcesContent":["$very-light-grey: #e7e7e7;\r\n$light-grey: #d5d5d5;\r\n\r\n.search-row {\r\n  border-bottom: 1px solid $light-grey;\r\n\r\n  display: flex;\r\n  gap: 12px;\r\n  padding: 10px;\r\n  align-items: center;\r\n\r\n  &__searchbar {\r\n    width: 100%;\r\n    background: $very-light-grey;\r\n    border-radius: 10px;\r\n\r\n    display: flex;\r\n    align-items: center;\r\n    gap: 4px;\r\n\r\n    &__icon {\r\n      padding-left: 8px;\r\n    }\r\n\r\n    &__input {\r\n      height: 30px;\r\n    }\r\n  }\r\n\r\n  &__create-button {\r\n    font-size: 18px;\r\n    margin-left: auto;\r\n\r\n    display: flex;\r\n    align-items: center;\r\n\r\n    background-color: var(--ion-color-primary);\r\n    color: white;\r\n\r\n    padding: 6px;\r\n    border-radius: 50%;\r\n\r\n    &:hover {\r\n      cursor: pointer;\r\n    }\r\n\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
