import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { JwtModule } from '@auth0/angular-jwt';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import Nora from '@primeng/themes/nora';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { providePrimeNG } from 'primeng/config';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { ChatPageModule } from './chat/chat.module';
import { BottomBarComponent } from './common/components/bottom-bar/bottom-bar.component';
import { LoggedInOutletComponent } from './common/components/logged-in-outlet/logged-in-outlet.component';
import { MainMenuComponent } from './common/components/main-menu/main-menu.component';
import { TopBarComponent } from './common/components/top-bar/top-bar.component';
import { DirectivesModule } from './directives/directives.module';
import { TranslocoRootModule } from './transloco-root.module';

function initializeKeycloak(keycloak: KeycloakService) {
    return () =>
        keycloak.init({
            config: {
                url: 'https://keycloak.teamkeysports.com/',
                realm: 'teamkeysports-dev',
                clientId: 'teamkeysports-app',
            },

            initOptions: {
                onLoad: 'check-sso',
                silentCheckSsoRedirectUri:
                    window.location.origin + '/assets/silent-check-sso.html',
                adapter: 'default'
            }
        });
}
@NgModule({ declarations: [AppComponent, TopBarComponent, BottomBarComponent, MainMenuComponent, LoggedInOutletComponent],
    bootstrap: [AppComponent], imports: [BrowserModule,
        CommonModule,
        MatIconModule,
        DirectivesModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        AuthModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: () => localStorage.getItem('ACCESS_TOKEN')
                //TODO: async retrieval is not supported at the moment
                // return new Promise(resolve => {
                //     Storage.get({key: 'ACCESS_TOKEN'}).then(res => resolve(res.value));
                // });
            },
        }),
        BrowserAnimationsModule,
        ChatPageModule,
        KeycloakAngularModule,
        TranslocoRootModule,
        NgbPopoverModule], providers: [
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy
        },
        // {
        //     provide: RxStompService,
        //     useFactory: (jwtService: JwtHelperService) => {
        //         const rxStomp = new RxStompService();
        //         myRxStompConfig.connectHeaders = {
        //             Authorization: jwtService.tokenGetter()
        //         };
        //         rxStomp.configure(myRxStompConfig);
        //         // rxStomp.activate(); //TODO
        //         return rxStomp;
        //     },
        // },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeKeycloak,
            multi: true,
            deps: [KeycloakService]
        },
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimationsAsync(),
        providePrimeNG({
            theme: {
                preset: Nora,
                options: {
                    darkModeSelector: '.dark-mode'
                }
            }
        })
    ] })
export class AppModule { }
