import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap } from "rxjs/operators";
import { UsersService } from '../_services/users.service';
import { UserRoles } from '../common/enums/user-roles.enum';

@Injectable({
    providedIn: 'root'
})
export class FinishedRegistrationGuard  {

    constructor(private router: Router, private usersService: UsersService) {}

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const isAdmin$ = this.usersService.hasCurrentUserRole$(UserRoles.ADMIN);
        const hasUserMemberShips$ = this.usersService.getCurrentUserMemberships$().pipe(map(memberships => !!memberships));

        return isAdmin$.pipe(switchMap(isAdmin => {
            if (isAdmin) {
                return of([true, true]);
            }
            else {
                return hasUserMemberShips$.pipe(map(hasUserMemberShips => [hasUserMemberShips, false]));
            }
        }),
        map(([finishedRegistration, isAdmin]) => {
            if (!finishedRegistration) {
                return this.handleUnfinishedRegistration(state);
            }
            else {
                return this.handleFinishedRegistration(isAdmin, state);
            }
        }));
    }

    private handleUnfinishedRegistration(state: RouterStateSnapshot): boolean {
        if (state.url.startsWith("/complete-registration/")) {
            return true;
        }

        this.router.navigate(['/complete-registration']);
        return false;
    }

    private handleFinishedRegistration(isAdmin: boolean, state: RouterStateSnapshot): boolean {
        if (state.url === "/" || state.url.startsWith("/complete-registration/")) {

            if (isAdmin) {
                this.router.navigateByUrl("/organization-management");
            }
            else {
                this.router.navigateByUrl("/chats");
            }

            return false;
        }

        return true;
    }

}
