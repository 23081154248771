import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { BehaviorSubject, Observable, of, from } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { User } from '../_models';
import { Group } from '../_models/group.model';
import { BaseApiService } from './base-api.service';
import { UserRoles } from '../common/enums/user-roles.enum';

const PREFIX: string = "users";
@Injectable({
    providedIn: 'root'
})
export class UsersService extends BaseApiService {

    private loggedInUser$: Observable<any> = null;

    constructor(protected http: HttpClient, private keycloakService: KeycloakService) { 
        super(http);
    }

    protected getEndpointPrefix(): string {
        return PREFIX;
    }

    public getOrganizationStructure(): Observable<Group[]> {
        const teamsGroup = new Group();
        const teamA = new User(1, "player1", "Henrich Vegh", 1, UserRoles.PLAYER);
        const depGroup = new Group();
        const financeGroup = new Group();
        const financeUser = new User(2, "finance1", "Johanna Schimdt", 1, UserRoles.MANAGEMENT);

        teamsGroup.id = 1;
        teamsGroup.name = "Teams";
        teamsGroup.users = [teamA];

        financeGroup.id = 2;
        financeGroup.name = "Finance"
        financeGroup.users = [financeUser, teamA];

        depGroup.id = 3;
        depGroup.name = "Departments";
        depGroup.groups = [financeGroup];

        const structure = [teamsGroup, depGroup];

        return of(structure);
    }

    public getAllUsers(): Observable<User[]> {
        return this.getOrganizationStructure().pipe(map(groups => this.traverseHierarchyAndFindUsers(groups)));
    }

    public getUserGroups(user: User): Observable<Group[]> {
        return this.getOrganizationStructure().pipe(map(groups => {
            return this.traverseHierarchyAndFindUserGroups(user, groups);
        }));
    }

    public getGroup(groupId: number): Observable<Group> {
        return this.getOrganizationStructure().pipe(map(groups => this.traverseHierarchyAndFindGroup(groupId, groups)));
    }

    public getGroupStructure(groupId: number): Observable<(Group | User)[]> {
        return this.getOrganizationStructure().pipe(map(groups => {
            // return groups;
            return this.traverseHierarchyAndRetrieveGroupStructure(groupId, groups);
        }));
    }

    public initUser$(): Observable<any> {
        return from(this.keycloakService.loadUserProfile()).pipe(switchMap(profile => {
            const body = {
               email: profile.email,
	           name: profile.firstName,
	           surname: profile.lastName
            }

            return this.http.post<any>(this.endpointUrl(`check`), body);
        }), shareReplay(1))
    }

    public getCurrentUser$(): Observable<User> {
        if (!this.loggedInUser$) {
            this.loggedInUser$ = from(this.keycloakService.loadUserProfile()).pipe(switchMap(profile => {
                const body = {
                   email: profile.email,
                   name: profile.firstName,
                   surname: profile.lastName
                }
    
                return this.http.post<User>(this.endpointUrl(`check`), body);
            }), shareReplay(1));
        }

        return this.loggedInUser$;
    }

    public getUsers(): Observable<any> {
        return this.http.get(this.endpointUrl(''));
    }

    public getCurrentUserMemberships$(): Observable<number> {
        return this.getCurrentUser$().pipe(
            map(user => user.memberships)
        );
        // return of({
        //     memberships: localStorage.getItem("memberships") ? 1 : 0
        // });

        // const id = "sdf";
        // this.keycloakService.loadUserProfile().then(p => console.log(p));
        // return this.http.get<any>(this.endpointUrl(`${id}/memberships`));
    }

    public hasCurrentUserRole$(role: UserRoles): Observable<boolean> {
        return this.getCurrentUser$().pipe(map(user => user.role === role));
    }

    private traverseHierarchyAndRetrieveGroupStructure(groupId: number, groups: Group[]): (Group | User)[] {
        for (let group of groups) {
            if (group.id === groupId) {
                let result = [];

                if (group.groups?.length) {
                    result.push(...group.groups);
                }

                if (group.users?.length) {
                    result.push(...group.users);
                }

                return result;
            }
        }

        for (let group of groups) {
            if (group.groups?.length) {
                const result = this.traverseHierarchyAndRetrieveGroupStructure(groupId, group.groups);

                if (result.length) {
                    return result;
                }
            }
        }

        return [];
    }

    private traverseHierarchyAndFindGroup(groupId: number, groups: Group[]): Group {

        for (let group of groups) {
            if (group.id === groupId) {
                return group;
            }
        }

        for (let group of groups) {
            if (group.groups) {
                const result = this.traverseHierarchyAndFindGroup(groupId, group.groups);
                if (result) {
                    return result;
                }
            }
        }

        return null;
    }

    private traverseHierarchyAndFindGroups(groups: Group[]): Group[] {
        const flattened = [];

        groups.forEach(group => {
            flattened.push(group);

            if (group.groups?.length) {
                flattened.push(...this.traverseHierarchyAndFindGroups(group.groups));
            }
        });

        return flattened;
    }

    private traverseHierarchyAndFindUsers(groups: Group[]): User[] {
        const users = [];

        groups.forEach(group => {
            if (group.users?.length) {
                users.push(...group.users);
            }

            if (group.groups?.length) {
                users.push(...this.traverseHierarchyAndFindUsers(group.groups));
            }
        });

        const uniqueUsers = [...new Map(users.map(user =>
            [user.id, user])).values()];

        return uniqueUsers;
    }

    private traverseHierarchyAndFindUserGroups(user: User, groups: Group[]): Group[] {
        const memberOf = [];

        groups.forEach(group => {
            if (group.users?.some(u => u.id === user.id)) {
                memberOf.push(group);
            }

            if (group.groups?.length) {
                memberOf.push(...this.traverseHierarchyAndFindUserGroups(user, group.groups));
            }
        });

        return memberOf;
    }
}
