import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { UsersService } from '../_services/users.service';
import {map, take} from "rxjs/operators";
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FinishedRegistrationGuard implements CanActivate {

    constructor(private router: Router, private keycloak: KeycloakService, private usersService: UsersService) {}

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const adminRole = 'teamkey-admin';
        // const userRole = 'teamkey-user';
        const keycloakRoles = JSON.parse(localStorage.getItem("roles")) || [];// TODO: get real roles from keycloak!! this.keycloak.getUserRoles();
        const userMemberShips$ = this.usersService.getCurrentUserMemberships().pipe(map(user => [user?.memberships, false]));
        const finishedRegistration$ = keycloakRoles.includes(adminRole) ? of([true, true]) : userMemberShips$;
        
        return finishedRegistration$.pipe(map(([finishedRegistration, isAdmin]) => {
            if (!finishedRegistration) {
                return this.handleUnfinishedRegistration(state);
            }
            else {
                return this.handleFinishedRegistration(isAdmin, state);
            }
        }));

    }

    private handleUnfinishedRegistration(state: RouterStateSnapshot): boolean {
        if (state.url.startsWith("/complete-registration/")) {
            return true;
        }

        this.router.navigate(['/complete-registration']);
        return false;
    }

    private handleFinishedRegistration(isAdmin: boolean, state: RouterStateSnapshot): boolean {
        console.log("sdfdfs");
        if (state.url === "/" || state.url.startsWith("/complete-registration/")) {

            if (isAdmin) {
                this.router.navigateByUrl("/organization-management");
            }
            else {
                this.router.navigateByUrl("/chats");
            }

            return false;
        }

        return true;
    }

}
